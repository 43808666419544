import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

// services
import {BaseRestService} from '../../../shared/providers/base-rest.service';

// models
import {UserModel} from '../../../shared/models/user.model';
import {userGetter} from '../../../shared/utils/helpers';

@Injectable({
  providedIn: 'root'
})
// TODO: Change Model ???
export class ProfileService extends BaseRestService<UserModel> {

  private readonly URL = 'user';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getUser(id: number): Observable<UserModel> {
    return this.getById(this.URL, id);
  }

  public getCurrentUser(): Observable<UserModel> {
    const userID = userGetter().id;

    if (!userID) {
      throwError('Could not get user data.');
      return null;
    }

    return this.getById(this.URL, userID);
  }

  public editProfile(data: Partial<UserModel>): Observable<UserModel> {
    return this.editPatch(this.URL, data, data.id);
  }
}
