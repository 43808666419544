import {AbstractControl, FormGroup} from '@angular/forms';

/**
 * @description Custom validators for form fields
 * @export
 */
export abstract class CustomValidators {

  /**
   * @description Checks if values of two fields are matching.
   */
  public static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public static editPasswordValidator(currentPassword: string, newPasswordsControlName: Array<string>) {
    return (formGroup: FormGroup) => {
      const currentPasswordControl = formGroup.controls[currentPassword];

      for (const controlName of newPasswordsControlName) {
        const control = formGroup.controls[controlName];

        // check if current password is not typed, but new passwords are typed
        if (!currentPasswordControl.value && control.value) {
          console.log(1);
          currentPasswordControl.setErrors({'required-for-new-password': true});
          continue;
        }

        // current password has value and new password has errors
        if (control.errors && currentPasswordControl.value) {
          continue;
        }

        // current password has value, but new passwords do not
        if (!control.value && currentPasswordControl.value) {
          control.setErrors({required: true});
          continue;
        }

        control.setErrors(null);
      }
    };
  }

  public static onlyPositiveNumbers(control: AbstractControl): { [key: string]: boolean } | null {
    const value = +control.value;

    if (value && value > 0) {
      return null;
    }

    return {onlyDigits: true};
  }
}

export const passwordValidator =
  /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)(?=.*[.*+?^$#@!%+=&_{}()|[\W-\]\\]+.*)[0-9a-zA-Z.*+?^$#@!%+=&_{}()|[\W-\]\\]{8,}$/;
