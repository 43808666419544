import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

// services
import {BaseRestService} from '../../../shared/providers/base-rest.service';

// models
import {ContestPayloadModel} from '../models/contest-single.model';
import {NameGeneratorModel} from '../../generate-name-steps/models/name-generator.model';
import {SuccessModel} from '../../../shared/models/success.model';
import {
  ProjectCardContestPayloadModel,
  ProjectCardContestModel
} from '../../generate-name-steps/models/project-card-contest.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectCardContestService extends BaseRestService<ProjectCardContestModel> {

  private readonly URL = 'create-project-card-contest';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public saveFavoritesNamesForCard(cardID, favoriteRows: Set<NameGeneratorModel>): Observable<any> {
    if (favoriteRows.size <= 0) {
      return of(true);
    }

    const data: ProjectCardContestPayloadModel = {
      card: cardID,
      brand_name_domains: this.prepareFavoritesForRequest(favoriteRows)
    };
    return this.post<ProjectCardContestPayloadModel, any>(this.URL, data);
  }

  private prepareFavoritesForRequest(data: Set<NameGeneratorModel>): Array<Array<ProjectCardContestModel>> {

    const value: Array<Array<ProjectCardContestModel>> = [];

    for (const item of data.values()) {
      const availableSocials = Object.keys(item.social).reduce((newObj, c) => {
        if (item.social[c]) {
          newObj[c] = true;
        }

        return newObj;
      }, {});

      const availableDomains = Object.keys(item.domains).reduce((newObj, c) => {
        if (item.domains[c]) {
          newObj[c] = true;
        }

        return newObj;
      }, {});

      value.push([
        {
          brand_name: item.name,
          social_and_domains: {
            ...availableDomains,
            ...availableSocials
          }
        }
      ]);
    }

    console.log('VALUE:', value);
    return value;
  }

  public createContest(data: ContestPayloadModel): Observable<SuccessModel> {
    return this.http.patch<SuccessModel>(`${this.apiUrl}${this.URL}/create_contest/`, data);
  }
}
